<template>
    <v-row class="h-100">
        <background page="subscriptions" v-if="!loading && resources.length > 0"></background>

        <v-col v-if="!loading" class="h-100">
            <v-row v-if="resources.length > 0">
                <v-col cols="12">
                    <v-card flat class="rounded-xl shadow" v-if="!loading && resources.length > 0">
                        <v-card-title class="justify-center petu-green">Evidencias</v-card-title>
                        <v-card-text>
                            <v-list two-line>
                                <template v-for="(r, index) in resources">
                                    <v-list-item :key="r.id" class="px-0">
                                        <v-list-item-content>
                                            <v-list-item-title class="d-flex">
                                                {{ getProductName(r.productId) }}
                                                <v-spacer></v-spacer>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ getResourceName(r.productId, r.id) }}
                                                <div>{{ r.dateCreated }}</div>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider v-if="index < resources.length - 1" :key="index"></v-divider>
                                </template>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else class="h-100" align="center" color="blue" style="margin-top: -75px">
                <v-col class="text-caption">
                    <v-card class="rounded-xl">
                        <v-card-text class="text-center">
                            <v-icon size="75" class="petu-orange mb-3">mdi-alert-circle</v-icon>
                            <div>
                                <span>{{ alertText }}</span>
                                <span v-if="!isLoggedIn" class="ml-1"><router-link to="/login" class="link">aquí</router-link></span>
                            </div>
                        </v-card-text>
                    </v-card>
                 </v-col>
            </v-row>
        </v-col>

        <!-- TODO: make component -->
        <v-dialog v-if="isLoggedIn && resources.length > 0" v-model="loading" hide-overlay persistent width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span>Cargando evidencias</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { PetuCustomer } from '@/classes/customer'
import { PetuProduct } from '@/classes/product'
import { _st } from '@/softech'

import Background from '@/components/Background'

export default {
	name: 'Resources',
    components: { Background },
    data:() => ({
        resources: [],
        products: [],
        loading: true,
    }),
    async mounted() {
        if (this.isLoggedIn) {
            let customer = new PetuCustomer( this.customer );
            this.resources  = await customer.getResources();
            await this.getProducts();
        }

        this.loading = false
    },
    methods: {
        async getProducts() {
            for( const r of this.resources ) {
                const i = this.products.findIndex((p) => p.id === r.productId);
                if( i === -1 ) {
                    let product = new PetuProduct();
                    await product.load( r.productId );
                    await product.loadResources();
                    this.products.push( product.data );
                }
            }
        },
        getResourceName(productID, resourceID) {
            const p = this.products.find((p) => p.id === productID)
            if( !p ) 
                return '';

            const r = p.resources.find((r) => r.id === resourceID);
            return r ? r.name : '';
        },
        getProductName(id) { 
            const p = this.products.find((p) => p.id === id)
            return p ? p.name : ''
        },
    },
    computed: {
        ...mapGetters({ 
            customer: 'auth/customer', 
            isLoggedIn: 'auth/isLoggedIn' 
        }),
        alertText() { 
            return this.isLoggedIn
            ? 'Actualmente no tiene evidencias'
            : 'Para poder ver sus evidencias, ingrese a su cuenta'
        },
    }
}
</script>

<style scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
</style>